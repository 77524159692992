import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import CustomToggle from './CustomToggle';

import './styles.scss';
import { stopPropagation } from 'utils/helper';

const DropDownMenu = ({
  toggleLabel,
  toggleIcon,
  className,
  toggleClass,
  menuClass,
  menuOptions,
  selectedOption,
  onChange,
  ...rest
}) => {
  const handleOnchange = (eventKey, event) => {
    stopPropagation(event);
    onChange(eventKey);
  };

  return (
    <Dropdown
      className={`app-dropdown ${className}`}
      onSelect={handleOnchange}
      {...rest}
    >
      <Dropdown.Toggle
        as={CustomToggle}
        toggleClassName={toggleClass}
        toggleIcon={toggleIcon}
      >
        {toggleLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu className={`app-dropdown-menu ${menuClass}`}>
        {menuOptions?.map((menu, index) =>
          menu?.value === 'divider' ? (
            <Dropdown.Divider key={`${menu.value}-index`} />
          ) : (
            <Dropdown.Item
              key={`${menu.value}-index`}
              eventKey={menu.value}
              as="div"
              active={selectedOption === menu.value}
              // title={menu?.label}
              title={menu?.label?.replace(/\b\w/g, char => char.toUpperCase())}
            >
              {/* {menu.label} */}
              {menu?.label?.length > 44
                ? menu?.label?.slice(0, 44)?.concat('..')
                : menu?.label}
            </Dropdown.Item>
          )
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropDownMenu.propTypes = {
  className: PropTypes.string,
  toggleClass: PropTypes.string,
  toggleIcon: PropTypes.node,
  menuClass: PropTypes.string,
  toggleLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ).isRequired,
  selectedOption: PropTypes.string,
  onChange: PropTypes.func,
};

DropDownMenu.defaultProps = {
  toggleIcon: null,
  className: '',
  toggleClass: '',
  menuClass: '',
  toggleLabel: '',
  selectedOption: '',
  onChange: () => null,
};

export default DropDownMenu;
