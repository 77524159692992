import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  carryOutLogout,
  getUserData,
  prepareDropDownOptions,
  userTokenExists,
} from 'utils/common';
import { useAccountSettingQueries, useBudgetQueries } from 'utils/api/queries';
import ALL_ROUTES from 'config/routes';
import {
  useAccountSettingMutation,
  usePostReorderDetailSheet,
  usePostReorderLineItem,
  usePostReorderLineItemDetailSheet,
  usePostReorderSwimlane,
  useUserActivityMutation,
} from 'utils/api/mutations';
import { injectNavigator } from 'utils/api/api-handler';
import { toastStyle } from 'utils/helper';
// import { toastStyle } from 'utils/helper';

const appContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [unitTypes, setUnitTypes] = useState([]);
  const [userData, setUserData] = useState();
  const [permissions, setPermissions] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logoutMutation = useAccountSettingMutation.useLogoutMutation();
  const userActivityMutation =
    useUserActivityMutation.usePostUserActivityMutation();
  const useReorderSwimlane = usePostReorderSwimlane.usePostReorderSwimlane();
  const useReorderLineItem = usePostReorderLineItem.usePostReorderLineItem();
  const useReorderDetailSheet =
    usePostReorderDetailSheet.usePostReorderDetailSheet();
  const useReorderDetailLineItemSheet =
    usePostReorderLineItemDetailSheet.usePostReorderLineItemDetailSheet();

  injectNavigator(navigate);

  useEffect(() => {
    if (userTokenExists() && (!isUserLoggedIn || !userData)) {
      const userInfo = getUserData();
      setIsUserLoggedIn(true);
      userInfo && setUserData(userInfo);
      // navigate(ALL_ROUTES.HOME_SCREEN, { replace: true });
    }
  }, [pathname, isUserLoggedIn, userData, navigate]);

  const { data: unitTypeData } = useBudgetQueries.useUnitTypesQuery({
    enabled: isUserLoggedIn,
  });

  useEffect(() => {
    unitTypeData?.data?.length &&
      setUnitTypes(prepareDropDownOptions(unitTypeData?.data, 'name', 'id'));
  }, [unitTypeData]);

  const { data: permissionsData } = useBudgetQueries.usePermissionsQuery({
    enabled: isUserLoggedIn,
  });

  useEffect(() => {
    permissionsData?.data?.length &&
      setPermissions(
        prepareDropDownOptions(permissionsData?.data, 'permission_type', 'id')
      );
  }, [permissionsData]);

  const handleLogout = async (noToast = false) => {
    try {
      await logoutMutation.mutateAsync();
      !noToast &&
        toast.success('You have been logged out successfully.', toastStyle);
    } catch (e) {
      !noToast &&
        toast.error('An error occurred while log out from server.', toastStyle);
    } finally {
      carryOutLogout(setIsUserLoggedIn);
      navigate(ALL_ROUTES.AUTH_CHILDREN.LOGIN);
    }
  };

  const {
    status: getProfileStatus,
    data: profileData,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
    refetch: refetchProfile,
  } = useAccountSettingQueries.useProfileQuery({
    enabled: isUserLoggedIn,
  });
  const userProfile = profileData?.data?.[0];

  return (
    <appContext.Provider
      value={{
        isUserLoggedIn,
        setIsUserLoggedIn,
        userData,
        getProfileStatus,
        userProfile,
        isFetchingProfile,
        isErrorProfile,
        refetchProfile,
        unitTypes,
        permissions,
        handleLogout,
        userActivityMutation,
        useReorderSwimlane,
        useReorderLineItem,
        useReorderDetailSheet,
        useReorderDetailLineItemSheet,
      }}
    >
      {children}
    </appContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAppContext = () => {
  const {
    isUserLoggedIn,
    setIsUserLoggedIn,
    userData,
    getProfileStatus,
    userProfile,
    isFetchingProfile,
    isErrorProfile,
    refetchProfile,
    unitTypes,
    permissions,
    handleLogout,
    userActivityMutation,
    useReorderSwimlane,
    useReorderLineItem,
    useReorderDetailSheet,
    useReorderDetailLineItemSheet,
  } = useContext(appContext);

  return {
    isUserLoggedIn,
    setIsUserLoggedIn,
    userData,
    getProfileStatus,
    userProfile,
    isFetchingProfile,
    isErrorProfile,
    refetchProfile,
    unitTypes,
    permissions,
    handleLogout,
    userActivityMutation,
    useReorderSwimlane,
    useReorderLineItem,
    useReorderDetailSheet,
    useReorderDetailLineItemSheet,
  };
};
