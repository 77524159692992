import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Modal from 'components/Modal';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import DropDownMenu from 'components/DropDownMenu';
import Button from 'components/Button';
import { baseUrl, debounce, stopPropagation, toastStyle } from 'utils/helper';
import { useAppContext } from 'contexts/AppContext';
import { validateEmail } from 'utils/validator';
import { useAccountSettingQueries } from 'utils/api/queries';
import { ReactComponent as CopyLink } from 'assets/images/CopyLink.svg';

import CollaboratorsList from './CollaboratorsList';

import './styles.scss';
import { set } from 'react-hook-form';

const ShareBudget = () => {
  const {
    shareBudgetMutation,
    showShareBudget,
    hideShareBudgetModal,
    budgetData,
    refetchCollaborators,
    removeBudgetAccessMutation,
  } = useHomeScreenContext();
  const { permissions, userData } = useAppContext();
  const dropdownMenuRef = useRef();
  const itemRefs = useRef([]);
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');
  const [selectedEmails, setSelectedEmail] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState(
    permissions?.[0]?.value
  );
  const [searchParam, setSearchParam] = useState('');
  const [copyUrl, setCopyUrl] = useState('');
  const [alertText, setAlertText] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const { data: userSearchData } = useAccountSettingQueries.useSearchUserQuery({
    searchParam,
    enabled: Boolean(searchParam),
  });

  const updateSearchParam = useMemo(
    () => debounce(value => setSearchParam(value), 500),
    []
  );

  useEffect(() => {
    if (inputValue) {
      const searchValue = inputValue?.toLowerCase();
      const userOptions =
        userSearchData?.data?.filter(
          item =>
            (item?.name?.toLowerCase()?.includes(searchValue) ||
              item?.email?.toLowerCase()?.includes(searchValue)) &&
            !selectedEmails.find(
              selected =>
                selected?.value?.toLowerCase() === item?.email?.toLowerCase()
            ) &&
            item?.email !== userData?.email
        ) || [];
      setUserList(userOptions);
    }
  }, [userSearchData, selectedEmails, inputValue, userData]);

  useEffect(() => {
    inputValue?.length >= 3
      ? updateSearchParam(inputValue)
      : setSearchParam('');
  }, [inputValue, updateSearchParam]);

  useEffect(() => {
    setOpenMenu(Boolean(userList.length));
  }, [userList]);

  const handleChangePermission = selectedItem => {
    setSelectedPermission(selectedItem);
  };

  const handleRemoveBudgetAccess = userInfo => {
    removeBudgetAccessMutation.mutate(
      { id: userInfo?.id },
      {
        onSuccess: () => {
          toast.success(
            `Access has been removed for the user ${
              userInfo?.name || userInfo?.email
            }.`,
            toastStyle
          );
          refetchCollaborators?.();
        },
        onError: error => {
          const message =
            error?.response?.data?.message?.non_field_error?.[0] ||
            error?.response?.data?.non_field_errors?.[0];
          toast.error(
            message ||
              `An error occurred while revoking user access. Please try again later`,
            toastStyle
          );
        },
      }
    );
  };

  const handleShareBudget = async (action, userInfo) => {
    if (action === 'remove_access') {
      return handleRemoveBudgetAccess(userInfo);
    }

    if (!(selectedEmails?.length || userInfo?.email)) {
      return false;
    }

    const payload = userInfo?.email
      ? {
          budget: budgetData?.id,
          permissions: userInfo.permissions,
          collaborator_emails: [userInfo?.email],
        }
      : {
          budget: budgetData?.id,
          permissions: [selectedPermission],
          collaborator_emails: selectedEmails?.map(item => item.value),
        };

    try {
      await shareBudgetMutation.mutateAsync(payload);
      const message =
        action === 'resend'
          ? `Resent invite email to '${userInfo?.email}'.`
          : `User '${
              userInfo?.name || userInfo?.email
            }' has been granted with ${permissionLabel(
              userInfo?.permissions?.[0]
            )} access for the budget "${budgetData?.name}".`;
      toast.success(
        userInfo?.email
          ? message
          : `Budget "${budgetData?.name}" has been successfully shared with selected users.`,
        toastStyle
      );
      if (!userInfo?.email) {
        setSelectedEmail([]);
      }
      refetchCollaborators?.();
    } catch (error) {
      const message =
        error?.response?.data?.message?.non_field_error?.[0] ||
        error?.response?.data?.non_field_errors?.[0];
      toast.error(
        message ||
          `An error occurred while sharing the budget. Please try again later`,
        toastStyle
      );
    }
  };

  const handleCancel = () => {
    hideShareBudgetModal();
  };

  const setSelectedItem = item => {
    const email = item || {
      value: inputValue?.toLowerCase(),
      label: inputValue,
    };
    if (email && validateEmail(email?.value)) {
      setSelectedEmail(prev =>
        prev?.find(tag => tag?.value === email?.value) ? prev : [...prev, email]
      );
      setInputValue('');
      setOpenMenu(false);
    }
  };

  const setAsSelected = useMemo(
    () =>
      debounce(newValue => {
        setSelectedItem({ value: newValue, label: newValue });
      }, 2000),
    []
  );

  const handleInputChange = event => {
    let newValue = event?.target?.value;
    if (/[\s;,:]/.test(newValue)) {
      const emails = newValue.split(/[\s;,:]/);
      emails
        .filter(email => validateEmail(email?.toLowerCase()?.trim()))
        .forEach(email => {
          setSelectedItem({ value: email?.trim(), label: email?.trim() });
          const regex = new RegExp(email + '[\\s;,:]');
          newValue = newValue?.replace(regex, '');
        });
      setInputValue(newValue);
    } else {
      setInputValue(newValue);
    }
    setAsSelected(newValue);
  };

  const handleKeyDown = event => {
    switch (event.key) {
      case 'ArrowDown':
        setHighlightedIndex(prevIndex => (prevIndex + 1) % userList.length);
        setOpenMenu(true);
        event.preventDefault();
        break;
      case 'ArrowUp':
        setHighlightedIndex(prevIndex =>
          prevIndex === 0 ? userList.length - 1 : prevIndex - 1
        );
        setOpenMenu(true);
        event.preventDefault();
        break;
      case 'Enter':
        if (highlightedIndex >= 0 && highlightedIndex <= userList.length) {
          setSelectedItem({
            value: userList[highlightedIndex]?.email,
            label:
              userList[highlightedIndex]?.name ||
              userList[highlightedIndex]?.email,
          });
        }
        event.preventDefault();
        break;
      case 'Tab':
        setOpenMenu(false);
        setSelectedItem();
        setAsSelected('');
        event.preventDefault();
        break;
      case 'Escape':
        setOpenMenu(false);
        setAsSelected('');
        setInputValue('');
        event.preventDefault();
        break;
      default:
        return '';
    }
  };

  const handleOnChange = userId => {
    const item = userList?.find(
      user => user?.id?.toString() === userId?.toString()
    );
    setSelectedItem({ label: item?.name || item?.email, value: item?.email });
  };

  const handleBlur = () => {
    setSelectedItem();
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(copyUrl);
    setAlertText(true);
    toast.success('Budget URL copied to clipboard', toastStyle);
  };

  const handleClickOutside = event => {
    if (
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target)
    ) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    setCopyUrl(`${baseUrl}/home?budget=${budgetData?.id}`);
  }, []);
  useEffect(() => {
    if (highlightedIndex >= 0 && highlightedIndex < userList.length) {
      const item = itemRefs.current[highlightedIndex];
      const dropdownMenu = dropdownMenuRef.current;

      if (item && dropdownMenu) {
        const itemTop = item.offsetTop;
        const itemBottom = itemTop + item.clientHeight;
        const menuScrollTop = dropdownMenu.scrollTop;
        const menuHeight = dropdownMenu.clientHeight;

        if (itemBottom > menuScrollTop + menuHeight) {
          dropdownMenu.scrollTop = itemBottom - menuHeight;
        } else if (itemTop < menuScrollTop) {
          dropdownMenu.scrollTop = itemTop;
        }
      }
    }
  }, [highlightedIndex, userList.length]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const modalTitle = () => {
    return (
      <>
        <div className="d-flex">
          <div className="share-budget__title">Share Budget</div>
        </div>
        <div className="share-budget__sub-title">{budgetData?.name}</div>
      </>
    );
  };

  const handleDeleteEmail = email => e => {
    stopPropagation(e);
    setSelectedEmail(
      selectedEmails?.filter(selected => email?.value !== selected?.value) || []
    );
  };

  const permissionLabel = selected => {
    return permissions?.find(item => selected?.toString() === item?.value)
      ?.label;
  };

  const setInputFocus = () => {
    inputRef?.current?.focus();
  };

  const myPermission =
    budgetData?.my_permission === 'viewer'
      ? permissions.filter(data => data.label === 'viewer')
      : permissions;

  return (
    <Modal
      onHide={handleCancel}
      show={showShareBudget}
      size="sm"
      className="share-budget"
      bodyClass="share-budget__body"
      centered={true}
      title={modalTitle()}
      handleCopyUrl={handleCopyUrl}
      copyUrl={true}
    >
      <div>
        <div className="email-list-label">
          {' '}
          <div>
            {alertText && (
              <div className="d-flex">
                <div
                  className="ms-auto clipboard"
                  style={{
                    textAlign: 'left',
                    marginTop: '-30px',
                    width: '58%',
                  }}
                >
                  Budget link copied to clipboard. Only users who have been
                  shared budget will be able to access via share link.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="email-list-label">Enter names or emails</div>
      </div>
      <div className="share-budget-container">
        <div className="email-list-container" onClick={setInputFocus}>
          <div className="tags-container">
            {selectedEmails?.map(email => (
              <div className="tag" key={email?.value}>
                {email?.label}
                <CloseIcon
                  className="remove-icon"
                  onClick={handleDeleteEmail(email)}
                />
              </div>
            ))}
            <Dropdown
              onSelect={handleOnChange}
              className="email-list-dropdown"
              show={openMenu}
            >
              <Form.Control
                type="text"
                placeholder={
                  selectedEmails?.length
                    ? ''
                    : 'Jacob Santos, les.grossman@fakestudio.com'
                }
                onKeyDown={handleKeyDown}
                onChange={handleInputChange}
                onBlur={handleBlur}
                className={`tags-input`}
                autoComplete="off"
                value={inputValue}
                ref={inputRef}
              />
              {/* <Dropdown.Menu className="email-list-dropdown-menu">
                {userList?.map((item, index) => (
                  <Dropdown.Item
                    key={item?.id}
                    eventKey={item?.id}
                    active={index === highlightedIndex}
                  >
                    {item.name || item?.email}&nbsp;
                    {item?.name && `(${item?.email})`}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu> */}
              <Dropdown.Menu
                className="email-list-dropdown-menu"
                ref={dropdownMenuRef}
              >
                {userList?.map((item, index) => (
                  <Dropdown.Item
                    key={item?.id}
                    eventKey={item?.id}
                    active={index === highlightedIndex}
                    ref={el => (itemRefs.current[index] = el)}
                  >
                    {item.name || item?.email}&nbsp;
                    {item?.name && `(${item?.email})`}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="permissions">
            <DropDownMenu
              className="budget-permissions"
              menuOptions={myPermission}
              toggleLabel={permissionLabel(selectedPermission)}
              selectedOption={selectedPermission}
              onChange={handleChangePermission}
            />
          </div>
        </div>
        <Button
          label="Share"
          className="share-budget-btn"
          type="submit"
          disabled={!selectedEmails?.length}
          onClick={handleShareBudget}
        />
      </div>
      <CollaboratorsList
        handleShareBudget={handleShareBudget}
        permissionLabel={permissionLabel}
      />
    </Modal>
  );
};

export default ShareBudget;
