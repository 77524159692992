import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import {
  formatCurrency,
  formatDateTime,
  isArray,
  sortArray,
} from 'utils/helper';
import { getColumnConf } from 'utils/common';
import SwimlanePage from '../SwimlanePage';
import GridLoader from 'components/GridLoader';
import BudgetPDF from '../BudgetPDF';

import './styles.scss';

const MotionPictureBudget = React.forwardRef(
  (
    {
      includeComments,
      includeProductionDetails,
      budgetData,
      isLoading,
      getLabel,
      pdfGeneratorRef,
    },
    ref
  ) => {
    const { selectedBudget, getBudgetType } = useHomeScreenContext();
    const columns = getColumnConf(selectedBudget);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [contingency, setContingency] = useState(0);

    useEffect(() => {
      const totalCost =
        budgetData?.budget_swimlane?.reduce((total, swimlane) => {
          total = total + parseFloat(swimlane?.cost || 0);

          return total;
        }, 0) || 0;

      setBudgetTotal(totalCost);
      calculateContingency(totalCost);
    }, [budgetData]);

    const renderProductionDetails = () => {
      return (
        <div className="print-layout__settings">
          <div className="left-block">
            <div className="left-block__item">
              Budget Dated:{' '}
              {formatDateTime(
                selectedBudget?.updated_on || selectedBudget?.created_on,
                'MM/DD/YYYY'
              )}
            </div>
            {selectedBudget?.producers?.map((producer, index) => (
              <div className="left-block__item" key={`${producer}-${index}`}>
                Producer: {producer || ''}
              </div>
            ))}
            <div className="left-block__item">
              Director: {selectedBudget?.director || ''}
            </div>
          </div>
          <div className="right-block">
            <div className="right-block__item">
              Shooting Days: {selectedBudget?.shooting_days || ''}
            </div>
            {selectedBudget?.shooting_locations?.map((location, index) => (
              <div className="right-block__item" key={`${location}-${index}`}>
                Shooting Location: {location || ''}
              </div>
            ))}
            <div className="right-block__item">
              Script Pages: {selectedBudget?.script_pages || ''}
            </div>
            <div className="right-block__item">
              Principal Photography:{' '}
              {selectedBudget?.principal_photography || ''}
            </div>
            <div className="right-block__item">
              Wrap Principal: {selectedBudget?.wrap_principal || ''}
            </div>
            <div className="right-block__item">
              Script Version: {selectedBudget?.script_version || ''}
            </div>
          </div>
        </div>
      );
    };

    const productionDetailsConf = () => [
      {
        columns: [
          [
            {
              text: `Budget Dated: ${formatDateTime(
                selectedBudget?.updated_on || selectedBudget?.created_on,
                'MM/DD/YYYY'
              )}`,
              fontSize: '10',
              width: '50%',
              alignment: 'left',
            },
            ...(selectedBudget?.producers?.map(producer => ({
              text: `Producer: ${producer || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'left',
            })) || []),
            {
              text: `Director: ${selectedBudget?.director || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'left',
            },
          ],
          [
            {
              text: `Shooting Days: ${selectedBudget?.shooting_days || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'right',
            },
            ...(selectedBudget?.shooting_locations?.map(location => ({
              text: `Shooting Location: ${location || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'right',
            })) || []),
            {
              text: `Script Pages: ${selectedBudget?.script_pages || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'right',
            },
            {
              text: `Principal Photography: ${
                selectedBudget?.principal_photography || ''
              }`,
              fontSize: '10',
              width: '50%',
              alignment: 'right',
            },
            {
              text: `Wrap Principal: ${selectedBudget?.wrap_principal || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'right',
            },
            {
              text: `Script Version: ${selectedBudget?.script_version || ''}`,
              fontSize: '10',
              width: '50%',
              alignment: 'right',
            },
          ],
        ],
      },
    ];

    const calculateContingency = total => {
      setContingency(total * 0.1);
    };

    const printLayoutHeader = () => {
      return (
        <div className="print-layout__header">
          <div className="budget-name">
            {budgetData?.name || selectedBudget?.full_name}
          </div>
          <div className="page-counter"></div>
        </div>
      );
    };

    const printLayoutFooter = () => {
      return (
        <div className="print-layout__footer">
          <div className="left-section">
            {selectedBudget?.company_name} CONFIDENTIAL
          </div>
          <div className="middle-section">Created with CineBudget Pro</div>
          <div className="right-section">
            Prepared by {selectedBudget?.last_edited_by},{' '}
            {formatDateTime(
              selectedBudget?.updated_on || selectedBudget?.created_on,
              'MMMM Do, YYYY'
            )}
          </div>
        </div>
      );
    };

    const renderSwimlaneSummaryFooter = footerRow => {
      return (
        <tr>
          <td>{footerRow?.label}</td>
          <td>
            {formatCurrency(
              footerRow?.value || 0,
              selectedBudget?.primary_currency
            )}
          </td>
        </tr>
      );
    };

    const swimlaneSummaryHeader = () => {
      return (
        <thead>
          <tr>
            <th style={{ background: '#4285F4', color: 'white' }}>Category</th>
            <th style={{ background: '#4285F4', color: 'white' }}>Total</th>
          </tr>
        </thead>
      );
    };

    const renderSwimlanes = (swimlane, tabIndex) => {
      return (
        <tr key={`swimlane-${swimlane?.id}-${tabIndex}`}>
          <td>{swimlane?.name}</td>
          <td>
            {formatCurrency(
              swimlane?.cost || 0,
              selectedBudget?.primary_currency
            )}
          </td>
        </tr>
      );
    };

    const swimlaneConf = swimlaneData => {
      return [
        {
          margin: [0, 20, 0, 0],
          table: {
            widths: ['70%', '30%'],
            headerRows: 1,
            body: [
              [
                {
                  text: 'Category',
                  alignment: 'left',
                  fontSize: '10',
                  color: 'white',
                  bold: true,
                  fillColor: '#4285F4',
                },
                {
                  text: 'Total',
                  alignment: 'right',
                  fontSize: '10',
                  color: 'white',
                  bold: true,
                  fillColor: '#4285F4',
                },
              ],
              ...(swimlaneData?.map(swimlane => [
                {
                  text: swimlane?.name || '',
                  alignment: 'left',
                  fontSize: '10',
                },
                {
                  text:
                    formatCurrency(
                      swimlane?.cost || 0,
                      selectedBudget?.primary_currency
                    ) || '',
                  alignment: 'right',
                  fontSize: '10',
                },
              ]) || []),
              [
                {
                  text: 'Sub Total',
                  alignment: 'left',
                  fontSize: '10',
                  color: 'white',
                  fillColor: '#4285F4',
                },
                {
                  text: formatCurrency(
                    budgetTotal || 0,
                    selectedBudget?.primary_currency
                  ),
                  alignment: 'right',
                  fontSize: '10',
                  color: 'white',
                  fillColor: '#4285F4',
                },
              ],
              [
                {
                  text: 'Grand Total',
                  alignment: 'left',
                  fontSize: '10',
                  color: 'white',
                  fillColor: '#4285F4',
                },
                {
                  text: formatCurrency(
                    budgetTotal || 0, //+ contingency
                    selectedBudget?.primary_currency
                  ),
                  alignment: 'right',
                  fontSize: '10',
                  color: 'white',
                  fillColor: '#4285F4',
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 2;
            },
            paddingBottom: function (i, node) {
              return 2;
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 &&
                rowIndex !== node.table.body.length - 1
                ? '#F2F2F2'
                : null;
            },
          },
        },
        ...(detailedViewLayoutConf(swimlaneData) || []),
      ];
    };

    const detailedViewLayoutConf = swimlaneData => {
      const cols = columns?.filter(
        col => col.key !== 'comment' || includeComments
      );

      const allRows = tableRows(swimlaneData);

      return [
        {
          pageBreak: 'before',
          table: {
            widths: cols?.map(col =>
              col?.key === 'comment'
                ? '*'
                : col?.key === 'item'
                ? includeComments
                  ? '20%'
                  : '*'
                : 'auto'
            ),
            headerRows: 1,
            body: [
              [
                ...(cols?.map(col => ({
                  text: getLabel(col.label),
                  alignment: col?.key === 'item_total' ? 'right' : 'left',
                  fontSize: '10',
                  bold: true,
                })) || []),
              ],
              ...(allRows?.map(item => {
                if (item?.type === 'swimlane-row') {
                  return [
                    {
                      text: item?.rowData?.join(' ') || '',
                      alignment: 'left',
                      fontSize: '10',
                      bold: true,
                      colSpan: includeComments
                        ? item?.colSpan
                        : item?.colSpan - 1,
                      fillColor: '#4285F4',
                      color: 'white',
                    },
                    ...Array.from(
                      {
                        length: item.colSpan - (includeComments ? 1 : 2),
                      },
                      () => ({})
                    ),
                  ];
                } else if (item?.type === 'line-item-row') {
                  return [
                    {
                      colSpan: includeComments
                        ? item.colSpan
                        : item?.colSpan - 1,
                      fillColor: '#c6c7c8',
                      text: [
                        {
                          text: `${item?.rowData[0] || ''} > `,
                          alignment: 'left',
                          fontSize: '10',
                        },
                        {
                          text: item?.rowData?.slice(1)?.join(' > ') || '',
                          alignment: 'left',
                          fontSize: '10',
                          bold: true,
                        },
                      ],
                    },
                    ...Array.from(
                      { length: item?.colSpan - (includeComments ? 1 : 2) },
                      () => ({})
                    ),
                  ];
                } else if (item?.type === 'detailed-sheet-row') {
                  return cols?.map((col, index) => {
                    return {
                      text: `${col.prefix || ''}${
                        col.formatValue
                          ? col.formatValue(item?.rowData?.[col.key])
                          : col.key !== 'comment'
                          ? item?.rowData?.[col.key]
                          : item?.rowData?.[col.key]?.length > 22
                          ? item?.rowData?.[col.key]
                              ?.slice(0, 22)
                              ?.concat('...')
                          : item?.rowData?.[col.key]
                      }`,
                      alignment: col?.key === 'item_total' ? 'right' : 'left',
                      fontSize: '10',
                    };
                  });
                }
                return [];
              }) || []),
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 2;
            },
            paddingBottom: function (i, node) {
              return 2;
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 ? '#F2F2F2' : null;
            },
          },
        },
      ];
    };

    const getSwimlaneSummary = () => {
      let swimlaneSummary =
        // sortArray(budgetData?.budget_swimlane, 'id')
        budgetData?.budget_swimlane?.map(swimlane => {
          return {
            rowData: swimlane,
            type: 'swimlane',
            renderMethod: renderSwimlanes,
          };
        }) || [];
      swimlaneSummary = [
        ...swimlaneSummary,
        {
          rowData: { label: 'Sub Total', value: budgetTotal },
          type: 'swimlaneFooter',
          renderMethod: renderSwimlaneSummaryFooter,
        },
        /*
        {
          rowData: { label: 'Contingency (10%)', value: contingency },
          type: 'swimlaneFooter',
          renderMethod: renderSwimlaneSummaryFooter,
        },
        {
          rowData: {
            label: 'Total Fringes',
            value: formatCurrency(0, selectedBudget?.primary_currency),
          },
          type: 'swimlaneFooter',
          renderMethod: renderSwimlaneSummaryFooter,
        },*/
        {
          rowData: {
            label: 'Grand Total',
            value: budgetTotal, //+ contingency
          },
          type: 'swimlaneFooter',
          renderMethod: renderSwimlaneSummaryFooter,
        },
      ];
      return swimlaneSummary;
    };

    const renderDetailSheet = (key, rowData, className) => {
      return (
        <tr className={className} key={key}>
          {columns
            ?.filter(col => col.key !== 'comment' || includeComments)
            ?.map((col, index) => {
              return (
                <td
                  key={`${col.key}-row-${rowData?.id}-${key}-${index}`}
                  className={col.key === 'item' ? 'item-name-column' : ''}
                >
                  {col.prefix}
                  {col.formatValue
                    ? col.formatValue(rowData?.[col.key])
                    : col.key !== 'comment'
                    ? rowData?.[col.key]
                    : rowData?.[col.key]?.length > 22
                    ? rowData?.[col.key]?.slice(0, 22)?.concat('...')
                    : rowData?.[col.key]}
                </td>
              );
            })}
        </tr>
      );
    };

    const renderLineItemDetails = (key, rowData, className, colSpan) => {
      return (
        <tr className={className} key={key}>
          <td colSpan={colSpan}>
            {rowData[0]} &gt;&nbsp;
            <strong>{rowData?.slice(1)?.join(' > ')}</strong>
          </td>
        </tr>
      );
    };

    const renderSwimlaneDetails = (key, rowData, className, colSpan) => {
      return (
        <tr className={className} key={key}>
          <td colSpan={colSpan}>{rowData?.join(' ')}</td>
        </tr>
      );
    };

    const getSwimlaneItemDetails = (
      lineItems,
      swimlaneItems,
      swimlaneKey = '',
      detailsCollection = {}
    ) => {
      swimlaneItems = isArray(swimlaneItems) ? swimlaneItems : [];
      let details;

      // sortArray(lineItems, 'id')
      lineItems?.forEach(item => {
        const collectionKey = `${swimlaneKey}->${item.id}`;
        const collectionItems = [...swimlaneItems, item];

        if (item?.swimlane_item_detail_sheets?.length) {
          details = item?.swimlane_item_detail_sheets;
          detailsCollection[collectionKey] = {
            details,
            collectionItems: collectionItems,
          };
        } else {
          detailsCollection = getSwimlaneItemDetails(
            item?.children,
            collectionItems,
            collectionKey,
            detailsCollection
          );
        }
      });

      return detailsCollection;
    };

    const detailSheetRows = detailSheet => {
      return (
        // sortArray(detailSheet, 'id')
        detailSheet?.map((item, index) => {
          return {
            rowData: item,
            key: `detail-sheet-${item?.id}-${index}`,
            type: 'detailed-sheet-row',
          };
        }) || []
      );
    };

    const lineItemDetailsRows = (swimlane, lineItems) => {
      const swimlaneName = swimlane?.name;
      const detailsCollection =
        getSwimlaneItemDetails(lineItems, [], swimlane?.id) || {};

      return Object.keys(detailsCollection)?.reduce(
        (acc, collectionKey) => [
          ...acc,
          {
            rowData: [
              swimlaneName,
              ...(detailsCollection[collectionKey]?.collectionItems?.map(
                item =>
                  `${item?.name} (${formatCurrency(
                    item?.cost || 0,
                    selectedBudget?.primary_currency
                  )})`
              ) || []),
            ],
            colSpan: 8,
            type: 'line-item-row',
            key: collectionKey,
          },
          ...detailSheetRows(detailsCollection[collectionKey]?.details),
        ],
        []
      );
    };

    const tableRows = swimlaneData => {
      return swimlaneData?.reduce(
        (acc, swimlane) => [
          ...acc,
          {
            rowData: [
              `${swimlane?.name} (${formatCurrency(
                swimlane?.cost || 0,
                selectedBudget?.primary_currency
              )})`,
            ],
            colSpan: 8,
            type: 'swimlane-row',
            key: `swimlane-details-${swimlane?.id}`,
          },
          ...(swimlane?.swimlane_detail_sheets?.length > 0
            ? detailSheetRows(swimlane?.swimlane_detail_sheets)
            : lineItemDetailsRows(
                swimlane,
                swimlane?.budget_swimlane_lineitem
              )),
        ],
        []
      );
    };

    const detailsTableHeader = () => {
      return (
        <thead>
          <tr>
            {columns
              ?.filter(col => col.key !== 'comment' || includeComments)
              ?.map(col => (
                <th key={`header-${col.key}`}>{getLabel(col.label)}</th>
              ))}
          </tr>
        </thead>
      );
    };

    const getDetailedView = () => {
      const allRows = tableRows(budgetData?.budget_swimlane);
      const renderMethods = {
        'swimlane-row': renderSwimlaneDetails,
        'line-item-row': renderLineItemDetails,
        'detailed-sheet-row': renderDetailSheet,
      };

      return allRows?.map(item => {
        return {
          rowData: item.rowData,
          type: item.type,
          renderMethod: (rData, tabIndex) =>
            renderMethods[item.type]?.(
              item.key + '-' + tabIndex,
              item.rowData,
              item.type,
              item.colSpan > 0
                ? includeComments
                  ? item.colSpan
                  : item.colSpan - 1
                : ''
            ),
        };
      });
    };
    return (
      <>
        {budgetData?.budget_swimlane?.length > 0 && (
          <BudgetPDF
            budgetTotal={budgetTotal}
            budgetData={budgetData}
            selectedBudget={selectedBudget}
            budgetType={getBudgetType()}
            productionDetails={
              includeProductionDetails ? productionDetailsConf() : ''
            }
            swimlaneTable={swimlaneConf(budgetData?.budget_swimlane)}
            pdfGeneratorRef={pdfGeneratorRef}
          />
        )}
        <div className="budget-data-container" ref={ref}>
          {isLoading && <GridLoader />}
          {budgetData?.budget_swimlane?.length > 0 && (
            <>
              <SwimlanePage
                tableHeader={swimlaneSummaryHeader()}
                tableData={getSwimlaneSummary()}
                printLayoutFooter={printLayoutFooter()}
                printLayoutHeader={printLayoutHeader()}
                productionDetails={
                  includeProductionDetails ? renderProductionDetails() : ''
                }
                showTitle={true}
                tableClassName={'budget-details-table'}
                tableProps={{ striped: true, bordered: true, hover: true }}
              />
              <div className="page-break" />
              <SwimlanePage
                tableHeader={detailsTableHeader()}
                printLayoutFooter={printLayoutFooter()}
                printLayoutHeader={printLayoutHeader()}
                tableData={getDetailedView()}
                tableClassName={'budget-details-sheet-table'}
                tableProps={{ striped: true, bordered: true, hover: true }}
              />
            </>
          )}
        </div>
      </>
    );
  }
);

MotionPictureBudget.displayName = 'MotionPictureBudget';

MotionPictureBudget.defaultProps = {
  budgetData: [],
};

MotionPictureBudget.propTypes = {
  includeComments: PropTypes.bool.isRequired,
  includeProductionDetails: PropTypes.bool.isRequired,
  budgetData: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  pdfGeneratorRef: PropTypes.object.isRequired,
  getLabel: PropTypes.func.isRequired,
};

export default MotionPictureBudget;
