import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import {
  useBudgetMutation,
  usePostDuplicateCategory,
  usePostDuplicateCategoryLineItem,
  useTabsMutuation,
} from 'utils/api/mutations';
import { useBudgetQueries, useTabsQueries } from 'utils/api/queries';
import { useAppContext } from 'contexts/AppContext';
import ALL_ROUTES from 'config/routes';
import {
  getOpenTabsFromCache,
  getSelectedTabFromCache,
  // setOpenTabsInCache,
  setSelectedTabInCache,
  userTokenExists,
} from 'utils/common';
import PrintBudget from 'pages/HomeScreens/BudgetScreen/PrintBudget';

const homeScreenContext = createContext({});
export const HomeScreenContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { isUserLoggedIn, userData, permissions, handleLogout } =
    useAppContext();
  const [selectedBudget, setSelectedBudget] = useState();
  const [activeSwimlane, setActiveSwimlane] = useState({});
  const [swimlanes, setSwimlanes] = useState([]);
  const [projectTotal, setProjectTotal] = useState(0);
  const [addedContextualSuggestions, setAddedContextualSuggestions] = useState(
    {}
  );
  const queryClient = useQueryClient();
  const createTabsMutation = useTabsMutuation.useCreateTabMutation();
  const makeAciveTabMutation = useTabsMutuation.useMakeActiveTabMutation();
  const closeTabsMutation = useTabsMutuation.useCloseTabMutation();
  const createBudgetMutation = useBudgetMutation.useCreateBudgetMutation();
  const moveBudgetMutation = useBudgetMutation.useMoveBudgetMutation();
  const moveBudgetFolderMutation =
    useBudgetMutation.useMoveBudgetFolderMutation();
  const createFolderMutation = useBudgetMutation.useCreateFolderMutation();
  const renameFolderMutation = useBudgetMutation.useRenameFolderMutuation();
  const duplicateBudgetMutation =
    useBudgetMutation.useDuplicateBudgetMutation();
  const deleteBudgetMutation = useBudgetMutation.useDeleteBudgetMutation();
  const createSwimlaneMutation = useBudgetMutation.useCreateSwimlaneMutation();
  const addSubCategoryMutation = useBudgetMutation.useAddSubcategoryMutation();
  const updateSwimlaneMutation = useBudgetMutation.useUpdateSwimlaneMutation();
  const deleteSwimlaneMutation = useBudgetMutation.useDeleteSwimlaneMutation();
  const createSwimlaneDetailsMutation =
    useBudgetMutation.useCreateSwimlaneDetailsMutation();
  const updateSwimlaneDetailsMutation =
    useBudgetMutation.useUpdateSwimlaneDetailsMutation();
  const deleteSwimlaneDetailsMutation =
    useBudgetMutation.useDeleteSwimlaneDetailsMutation();
  const shareBudgetMutation = useBudgetMutation.useShareBudgetMutation();
  const removeBudgetAccessMutation =
    useBudgetMutation.useRemoveBudgetAccessMutation();
  const budgetDownloadMutation = useBudgetMutation.useBudgetDownloadMutation();
  const removeMultiBudgetsMutuation =
    useBudgetMutation.useRemoveMultiBudgetsMutuation();
  const removeFolderMutuation = useBudgetMutation.useRemoveFolderMutuation();
  const createDuplicateCategory =
    usePostDuplicateCategory.usePostDuplicateCategory();
  const createDuplicateCategoryLineItem =
    usePostDuplicateCategoryLineItem.usePostDuplicateCategoryLineItem();
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [showCreateBudget, setShowCreateBudget] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showDeleteBudget, setShowDeleteBudget] = useState(false);
  const [showDeleteMultiBudgets, setShowDeleteMultiBudgets] = useState(false);
  const [showDeleteFolder, setShowDeleteFolder] = useState(false);
  const [renameBudget, setRenameBudget] = useState();
  const [delData, setdelData] = useState();
  const [folderData, setFolderData] = useState();
  const [showShareBudget, setShowShareBudget] = useState(false);
  const [showBudgetSettings, setShowBudgetSettings] = useState(false);
  const [showLogOutModal, setShowLogoutModal] = useState(false);
  const [showNewBudgetId, setshowNewBudgetId] = useState(false);
  const [printShow, setPrintShow] = useState(false);
  const [keyStore, setKeyStore] = useState('');
  const [tabActive, setTabActive] = useState(null);
  const hideCreateBudgetModal = () => setShowCreateBudget(false);
  const showCreateBudgetModal = () => setShowCreateBudget(true);
  const hideCreateFolderModal = () => setShowCreateFolder(false);
  const showCreateFolderModal = () => setShowCreateFolder(true);
  const hideDeleteBudgetModal = () => setShowDeleteBudget(false);
  const showDeleteBudgetModal = () => setShowDeleteBudget(true);
  const showDeleteMultiBudgetModal = () => setShowDeleteMultiBudgets(true);
  const hideDeleteMultiBudgetModal = () => setShowDeleteMultiBudgets(false);
  const showDeleteFolderModal = () => setShowDeleteFolder(true);
  const hideDeleteFolderModal = () => setShowDeleteFolder(false);

  const setBudgetToRename = () => {
    setRenameBudget(budgetData);
    showCreateBudgetModal();
  };
  const setNewBudgetIdFunc = flag => {
    setshowNewBudgetId(flag);
  };
  const setSelectedDeldata = selData => {
    setdelData(selData);
    showDeleteMultiBudgetModal();
  };
  const setFolderSelectedDeldata = selData => {
    setFolderData(selData);
    showDeleteFolderModal();
  };
  const setWindowReload = () => {
    window?.location?.reload();
  };
  const clearBudgetRename = () => setRenameBudget(null);
  const showShareBudgetModal = () => setShowShareBudget(true);
  const hideShareBudgetModal = () => setShowShareBudget(false);
  const showBudgetSettingsModal = () => setShowBudgetSettings(true);
  const hideBudgetSettingsModal = () => setShowBudgetSettings(false);
  const hideLogOutModal = () => setShowLogoutModal(false);
  const logOutModalShow = () => setShowLogoutModal(true);

  const {
    status: getBudgetsStatus,
    data: budgetListDataa,
    isFetching: isFetchingBudgets,
    isError: isErrorBudgets,
    refetch: refetchBudgets,
  } = useBudgetQueries.useBudgetQuery({
    enabled: Boolean(isUserLoggedIn),
  });
  const budgetList = budgetListDataa?.data;
  const [budgetListData, setbudgetListData] = useState(budgetList);
  //fetch  open tabs
  const {
    status: getTabsStatus,
    data: tabsData,
    isFetching: isFetchingTabs,
    isError: isErrorTabs,
    refetch: refetchTabs,
  } = useTabsQueries.useOpenTabsQuery({
    enabled: Boolean(isUserLoggedIn),
  });
  const openTabsListResponse = tabsData?.data;
  const [openTabsList, setOpenTabsList] = useState(openTabsListResponse);
  const [openTabsListInitial, setOpenTabsListInitial] =
    useState(openTabsListResponse);
  const [getbudgetDropdownData, setBudgetDropdownData] = useState([]);

  const {
    status: getBudgetIdStatus,
    data: budgetIdData,
    isFetching: isFetchingBudgetId,
    isError: isErrorBudgetId,
    refetch: refetchBudgetId,
  } = useBudgetQueries.useBudgetByIdQuery({
    enabled: Boolean(isUserLoggedIn && selectedBudget?.budget),
    budgetId: selectedBudget?.budget,
  });
  const budgetData = budgetIdData?.data;

  const {
    status: getBudgetTypeStatus,
    data: budgetTypesData,
    isFetching: isFetchingBudgetTypes,
    isError: isErrorBudgetTypes,
    refetch: refetchBudgetTypes,
  } = useBudgetQueries.useBudgetTypeQuery({
    enabled: Boolean(isUserLoggedIn),
  });
  const budgetTypes = budgetTypesData?.data;

  const {
    status: getFolderType,
    data: foldersData,
    isFetching: isFetchingFolders,
    isError: isErrorFoldersTypes,
    refetch: refetchFolders,
  } = useBudgetQueries.useFolderBudgetQuery({
    enabled: Boolean(isUserLoggedIn),
  });
  const foldersList = foldersData?.data;
  const [folderListing, setFolderListing] = useState(foldersList);
  const [queryParameters] = useSearchParams();
  useEffect(() => {
    if (
      queryParameters.get('budget') != null ||
      queryParameters.get('budget') != undefined
    ) {
      handleCreateTabs(queryParameters.get('budget'));
    }
  }, [queryParameters.get('budget')]);
  const {
    status: getContextualSuggestionsStatus,
    data: contextualSuggestionsData,
    isFetching: isFetchingContextualSuggestions,
    isError: isErrorContextualSuggestions,
    refetch: refetchContextualSuggestions,
  } = useBudgetQueries.useContextualSuggestionsQuery({
    enabled: Boolean(isUserLoggedIn),
  });
  const contextualSuggestions = contextualSuggestionsData?.data;

  const {
    status: getCollaboratorsStatus,
    data: collaboratorsData,
    isFetching: isFetchingCollaborators,
    isError: isErrorCollaborators,
    refetch: refetchCollaborators,
  } = useBudgetQueries.useCollaboratorsQuery({
    enabled: Boolean(isUserLoggedIn && selectedBudget?.budget),
    budgetId: selectedBudget?.budget,
  });
  const collaboratorsList = collaboratorsData?.data;

  const {
    status: getBudgetPermissionsStatus,
    data: budgetPermissionData,
    isFetching: isFetchingBudgetPermissions,
    isError: isErrorBudgetPermissions,
    refetch: refetchBudgetPermissions,
  } = useBudgetQueries.useBudgetPermissionsQuery({
    enabled: Boolean(isUserLoggedIn && selectedBudget?.budget),
    budgetId: selectedBudget?.budget,
  });
  const budgetPermissions = budgetPermissionData?.data?.[0];

  const { pathname } = useLocation();

  useEffect(() => {
    if (!userTokenExists()) {
      navigate(ALL_ROUTES.AUTH_CHILDREN.LOGIN);
      handleLogout(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, navigate]);

  useEffect(() => {
    // Your code here
    setbudgetListData(budgetList); // Ensure it's defined here
  }, [budgetList]);

  useEffect(() => {
    setOpenTabsListInitial(tabsData?.data);
  }, [tabsData]);

  useEffect(() => {
    setFolderListing(foldersData?.data);
  }, [foldersData]);

  useEffect(() => {
    if (tabsData && tabsData?.data) {
      let openTabs = [];
      let budgetListDataVar = [];
      const budgetDrop = [];
      if (tabsData?.data?.length > 4) {
        openTabs = tabsData?.data.slice(0, 4);
        budgetListDataVar = tabsData?.data.slice(4, tabsData?.data.length);
      } else {
        openTabs = tabsData?.data;
      }
      for (let i = 0; i < budgetListDataVar?.length; i++) {
        budgetDrop?.push({
          label: budgetListDataVar[i]?.name,
          value: budgetListDataVar[i]?.budget,
        });
      }
      setbudgetListData(budgetDrop);
      setBudgetDropdownData(budgetDrop);
      setOpenTabsList(openTabs);
      setSelectedBudget(openTabs.find(tab => tab?.is_active) || openTabs[0]);
    }
  }, [tabsData]);

  useEffect(() => {
    if (!selectedBudget) {
      const selectedTab = getSelectedTabFromCache();
      setSelectedBudget(selectedTab || budgetList?.[0]);
    }
  }, [budgetList, setSelectedBudget, selectedBudget]);

  useEffect(() => {
    selectedBudget && setSelectedTabInCache(selectedBudget);
  }, [selectedBudget]);

  const isBudgetOwner = useCallback(
    budget => {
      const selected = budget?.id ? budget : selectedBudget;
      return selected?.created_by_email === userData?.email;
    },
    [selectedBudget, userData]
  );

  const hasEditAccess = useCallback(() => {
    return (
      isBudgetOwner() ||
      budgetPermissions?.permissions?.some(permission => {
        return (
          permissions?.find(
            perm => permission?.toString() === perm?.value?.toString()
          )?.label === 'editor'
        );
      })
    );
  }, [budgetPermissions, permissions, isBudgetOwner]);

  const hasBudgetEditAccessInList = useCallback(
    budgetPermission => {
      return budgetPermission === 'editor';
    },
    [budgetPermissions, permissions, isBudgetOwner]
  );

  const handleCreateTabs = async budget_id => {
    const payload = [];
    payload?.push({
      budget: budget_id,
    });
    try {
      const response = await createTabsMutation.mutateAsync(payload);
      await makeAciveTabMutation.mutateAsync(response?.data?.[0]?.id);
      const newTabs = response?.data;
      await refetchTabs();
      toast.dismiss();
    } catch (error) {
      console.log('Error in tabs creation', error);
    }
  };

  const duplicateBudget = withValues => {
    toast.loading(
      `Please wait while we are copying budget ${selectedBudget?.name} ...`
    );
    duplicateBudgetMutation.mutate(
      { budget_id: selectedBudget?.budget, withValues },
      {
        onSuccess: response => {
          const newBudget = { ...response?.data?.data, duplicate: true };
          if (newBudget?.id) {
            refetchBudgets?.();
            refetchBudgetId?.();
            handleCreateTabs(newBudget?.id);
            // selectTab(newBudget?.id, newBudget);
          }
        },
        onError: () => {
          toast.dismiss();
        },
      }
    );
  };

  const duplicateBudgetWithValues = () => duplicateBudget(true);

  const changeTab = (tab, callActive = false) => {
    if (tab?.id?.toString() !== selectedBudget?.budget?.toString()) {
      setShowDetailedView(false);
    }

    setSelectedBudget(tab);

    callActive && !tab?.duplicate && makeAciveTabMutation.mutateAsync(tab?.id);
  };

  const renameTab = useCallback(
    (tabId, renamedBudget) => {
      renamedBudget ||= budgetList?.find(
        budget => budget?.id?.toString() === tabId?.toString()
      );
      let openTabs = openTabsList || [];

      openTabs = openTabs.map(tab => {
        if (tab?.budget?.toString() === tabId?.toString()) {
          return {
            ...tab,
            name: renamedBudget?.name,
          };
        }
        return tab;
      });
      setOpenTabsList(openTabs);
    },
    [openTabsList, budgetList]
  );

  const selectTab = useCallback(
    (tabId, selectedTab) => {
      selectedTab ||= budgetList?.find(
        budget => budget?.id?.toString() === tabId?.toString()
      );
      let openTabs = openTabsList || [];

      if (!openTabs?.length) {
        openTabs = [selectedTab];
      } else if (
        !openTabsList?.find(tab => tab?.id?.toString() === tabId?.toString())
      ) {
        const startIndex = openTabsList?.length > 3 ? 1 : 0;

        // I have changed 3 to 4 in slice
        openTabs = [...openTabsList.slice(startIndex, 4), selectedTab];
      } else if (selectedTab?.id) {
        openTabs = openTabsList.map(tab =>
          tab?.id?.toString() === selectedTab?.id?.toString()
            ? selectedTab
            : tab
        );
      }
      openTabs.forEach(item => {
        if (!('budget' in item)) {
          // If not, add the "budget" key with a value of null
          item.budget = item?.id;
        }
      });
      let openTabsTemp = [];
      let budgetListDataVar = [];
      const budgetDrop = [];
      openTabsTemp = openTabs;
      budgetListDataVar = tabsData?.data?.filter(
        item => !openTabs.some(newItem => newItem?.budget === item?.budget)
      );

      for (let i = 0; i < budgetListDataVar?.length; i++) {
        budgetDrop?.push({
          label: budgetListDataVar[i]?.name,
          value: budgetListDataVar[i]?.budget,
        });
      }
      setbudgetListData(budgetDrop);
      setBudgetDropdownData(budgetDrop);
      // setOpenTabsListInitial(budgetDrop);
      setOpenTabsList(openTabsTemp);
      selectedTab && changeTab(selectedTab);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openTabsList, budgetList]
  );

  const selectTabFromDropdown = useCallback(async tabId => {
    const selectedTabIndex = openTabsListInitial?.findIndex(
      budget => budget?.budget?.toString() === tabId?.toString()
    );

    const newList = JSON.parse(JSON.stringify(openTabsListInitial));

    const elementToRemove = newList.splice(selectedTabIndex, 1);
    const firstElement = newList.shift();
    newList.push(firstElement);
    newList.splice(3, 0, elementToRemove[0]);

    const reversedList = newList.reverse()?.map(data => {
      return {
        budget: data?.budget,
      };
    });

    // const listToUpdate = JSON.parse(JSON.stringify(newList));

    // const openTabs = listToUpdate?.splice(-4, 4).reverse();

    // const menuItems = listToUpdate.map(data => {
    //   return {
    //     label: data?.name,
    //     value: data?.budget,
    //   };
    // });

    const newTabList =
      (await createTabsMutation.mutateAsync(reversedList))?.data || [];
    const newOpenTabListData = JSON.parse(JSON.stringify(newTabList));
    setOpenTabsListInitial(newOpenTabListData);
    // changeTab(newTabList[3], true);
    // setOpenTabsList(newTabList.splice(0, 4));
    const openTabs = newTabList?.splice(0, 4);

    const menuItems = newTabList.map(data => {
      return {
        label: data?.name,
        value: data?.budget,
      };
    });

    setbudgetListData(menuItems);
    setBudgetDropdownData(menuItems);
    setOpenTabsList(openTabs);
    changeTab(openTabs[3], true);
  });

  // const selectTabFromDropdown = useCallback(
  //   (tabId, selectedTab) => {
  //     selectedTab ||= openTabsListInitial?.find(
  //       budget => budget?.id?.toString() === tabId?.toString()
  //     );
  //     let openTabs = openTabsList || [];

  //     if (!openTabs?.length) {
  //       openTabs = [selectedTab];
  //     } else if (
  //       !openTabsList?.find(tab => tab?.id?.toString() === tabId?.toString())
  //     ) {
  //       const startIndex = openTabsList?.length > 3 ? 1 : 0;

  //       // I have changed 3 to 4 in slice
  //       openTabs = [...openTabsList.slice(startIndex, 4), selectedTab];
  //     } else if (selectedTab?.id) {
  //       openTabs = openTabsList.map(tab =>
  //         tab?.id?.toString() === selectedTab?.id?.toString()
  //           ? selectedTab
  //           : tab
  //       );
  //     }
  //     let openTabsTemp = [];
  //     let budgetListDataVar = [];
  //     const budgetDrop = [];
  //     openTabsTemp = openTabs;
  //     budgetListDataVar = tabsData?.data?.filter(
  //       item => !openTabs.some(newItem => newItem?.budget === item?.budget)
  //     );
  //     for (let i = 0; i < budgetListDataVar?.length; i++) {
  //       budgetDrop?.push({
  //         label: budgetListDataVar[i]?.name,
  //         value: budgetListDataVar[i]?.id,
  //       });
  //     }
  //     setbudgetListData(budgetDrop);
  //     setBudgetDropdownData(budgetDrop);
  //     // setOpenTabsListInitial(budgetDrop);
  //     setOpenTabsList(openTabsTemp);
  //     selectedTab && changeTab(selectedTab);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [openTabsList, openTabsListInitial, budgetList]
  // );

  const updateSwimlaneItem = useCallback(
    (payload, onSuccess, onError) =>
      updateSwimlaneMutation.mutate(payload, { onSuccess, onError }),
    [updateSwimlaneMutation]
  );

  const updateSwimlaneCosts = useCallback(
    (detailItemTotal, selectedItem, swimlaneColumns = swimlanes) => {
      let swimlaneTotal = detailItemTotal;
      let index = swimlanes?.length - 1;
      let selected = selectedItem;

      [...swimlaneColumns].reverse()?.forEach(lane => {
        const {
          type: swimlaneType,
          selectedItem: swimlaneSelectedItem,
          budgetId,
        } = lane;
        const payload = {
          id: index === 0 ? budgetId : swimlaneSelectedItem?.id,
          type: swimlaneType,
        };
        const swimlaneData = queryClient.getQueryData([
          'get-swimlanes-in-budget',
          { ...payload },
        ]);
        const swimlaneItems = swimlaneData?.data;

        swimlaneTotal = swimlaneItems?.reduce((total, item) => {
          const lineItemSwimlaneMatch =
            index >= 1 && item.swimlane === selected?.swimlane;
          const lineItemChildrenParenMatch =
            index > 1 &&
            item.parent === selected?.parent &&
            lineItemSwimlaneMatch;
          const swimlaneBudgetMatch = index === 0 && item.budget === budgetId;
          if (
            item?.id === selected?.id &&
            (lineItemChildrenParenMatch ||
              lineItemSwimlaneMatch ||
              swimlaneBudgetMatch)
          ) {
            item.cost = isNaN(swimlaneTotal) ? 0 : swimlaneTotal;
            updateSwimlaneItem({ ...item, type: swimlaneType });
          }

          total += isNaN(item?.cost) ? 0 : parseFloat(item?.cost) || 0;
          setProjectTotal(total?.toFixed(2));

          return parseFloat(total?.toFixed(2));
        }, 0);

        selected = swimlaneSelectedItem;
        detailItemTotal = swimlaneTotal;

        queryClient.setQueryData(['get-swimlanes-in-budget', { ...payload }], {
          ...swimlaneData,
          data: swimlaneItems,
        });

        index--;
      });
    },
    [queryClient, swimlanes, updateSwimlaneItem, setProjectTotal]
  );

  const navigateToPrint = () => {
    navigate(ALL_ROUTES.HOME_SCREEN_CHILDREN.PRINT_BUDGET);
  };
  const exportToPdf = () => {
    // await handleDownloadPdf();
    setPrintShow(true);
  };
  const getBudgetType = () => {
    return (
      budgetTypes?.find(
        type =>
          type?.id?.toString() === selectedBudget?.template_type?.toString()
      )?.name || ''
    );
  };

  const menuActions = {
    'duplicate-budget': duplicateBudget,
    'duplicate-budget-with-values': duplicateBudgetWithValues,
    'new-budget': showCreateBudgetModal,
    'new-folder': showCreateFolderModal,
    'delete-budget': showDeleteBudgetModal,
    'delete-multi-budget': showDeleteMultiBudgetModal,
    'delete-folder': showDeleteFolderModal,
    'rename-budget': setBudgetToRename,
    'share-budget': showShareBudgetModal,
    'manage-collaborators': showShareBudgetModal,
    'email-share-link': showShareBudgetModal,
    'budget-settings': showBudgetSettingsModal,
    'print-budget': navigateToPrint,
    'export-to-pdf': exportToPdf,
  };

  const menuPermissions = useMemo(
    () => ({
      'duplicate-budget': isBudgetOwner() && selectedBudget?.budget,
      'duplicate-budget-with-values': isBudgetOwner() && selectedBudget?.budget,
      'delete-budget': isBudgetOwner() && selectedBudget?.budget,
      'rename-budget': isBudgetOwner() && selectedBudget?.budget,
      'share-budget': selectedBudget?.budget,
      'archive-budget': isBudgetOwner() && selectedBudget?.budget,
      'manage-collaborators': isBudgetOwner() && selectedBudget?.budget,
      'email-share-link': isBudgetOwner() && selectedBudget?.budget,
      'budget-settings': selectedBudget?.budget,
      'print-budget': selectedBudget?.budget,
    }),
    [isBudgetOwner, selectedBudget]
  );

  return (
    <>
      <homeScreenContext.Provider
        value={{
          createTabsMutation,
          makeAciveTabMutation,
          closeTabsMutation,
          createBudgetMutation,
          moveBudgetMutation,
          moveBudgetFolderMutation,
          createFolderMutation,
          renameFolderMutation,
          duplicateBudgetMutation,
          deleteBudgetMutation,
          createSwimlaneMutation,
          addSubCategoryMutation,
          updateSwimlaneMutation,
          deleteSwimlaneMutation,
          createSwimlaneDetailsMutation,
          createDuplicateCategory,
          createDuplicateCategoryLineItem,
          updateSwimlaneDetailsMutation,
          deleteSwimlaneDetailsMutation,
          shareBudgetMutation,
          removeBudgetAccessMutation,
          removeMultiBudgetsMutuation,
          removeFolderMutuation,
          budgetDownloadMutation,

          getBudgetsStatus,
          budgetList,
          isFetchingBudgets,
          isErrorBudgets,
          refetchBudgets,
          getTabsStatus,
          openTabsListResponse,
          isFetchingTabs,
          isErrorTabs,
          refetchTabs,
          getFolderType,
          foldersData,
          isFetchingFolders,
          isErrorFoldersTypes,
          refetchFolders,
          getBudgetIdStatus,
          budgetData,
          isFetchingBudgetId,
          isErrorBudgetId,
          refetchBudgetId,

          selectedBudget,
          setSelectedBudget,

          getBudgetTypeStatus,
          budgetTypes,
          isFetchingBudgetTypes,
          isErrorBudgetTypes,
          refetchBudgetTypes,

          getContextualSuggestionsStatus,
          contextualSuggestions,
          isFetchingContextualSuggestions,
          isErrorContextualSuggestions,
          refetchContextualSuggestions,

          getCollaboratorsStatus,
          collaboratorsList,
          isFetchingCollaborators,
          isErrorCollaborators,
          refetchCollaborators,

          getBudgetPermissionsStatus,
          budgetPermissions,
          isFetchingBudgetPermissions,
          isErrorBudgetPermissions,
          refetchBudgetPermissions,

          showDetailedView,
          setShowDetailedView,
          activeSwimlane,
          setActiveSwimlane,
          swimlanes,
          setSwimlanes,
          projectTotal,
          setProjectTotal,
          setOpenTabsList,
          budgetListData,
          openTabsList,
          openTabsListInitial,
          folderListing,
          getbudgetDropdownData,
          showCreateBudget,
          showCreateFolder,
          showDeleteBudget,
          showDeleteMultiBudgets,
          showDeleteFolder,
          renameBudget,
          duplicateBudget,
          duplicateBudgetWithValues,
          hideCreateBudgetModal,
          showCreateBudgetModal,
          hideCreateFolderModal,
          showCreateFolderModal,
          hideDeleteBudgetModal,
          showDeleteBudgetModal,
          hideDeleteMultiBudgetModal,
          showDeleteMultiBudgetModal,
          showDeleteFolderModal,
          hideDeleteFolderModal,
          setBudgetToRename,
          clearBudgetRename,
          showShareBudgetModal,
          hideShareBudgetModal,
          showBudgetSettings,
          showBudgetSettingsModal,
          hideBudgetSettingsModal,
          showLogOutModal,
          showNewBudgetId,
          printShow,
          setPrintShow,
          keyStore,
          setKeyStore,
          tabActive,
          setTabActive,
          setNewBudgetIdFunc,
          hideLogOutModal,
          logOutModalShow,
          showShareBudget,
          addedContextualSuggestions,
          setAddedContextualSuggestions,
          menuActions,
          menuPermissions,
          changeTab,
          selectTab,
          renameTab,
          selectTabFromDropdown,
          updateSwimlaneCosts,
          isBudgetOwner,
          hasEditAccess,
          hasBudgetEditAccessInList,
          getBudgetType,
          updateSwimlaneItem,
          delData,
          folderData,
          setSelectedDeldata,
          setFolderSelectedDeldata,
          setWindowReload,
        }}
      >
        {children}
        {printShow && (
          <div style={{ display: 'none' }}>
            <PrintBudget exportPdf={printShow} />
          </div>
        )}
      </homeScreenContext.Provider>
    </>
  );
};

HomeScreenContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useHomeScreenContext = () => {
  const {
    delData,
    folderData,
    setWindowReload,
    setSelectedDeldata,
    setFolderSelectedDeldata,
    createTabsMutation,
    makeAciveTabMutation,
    closeTabsMutation,
    createBudgetMutation,
    moveBudgetMutation,
    moveBudgetFolderMutation,
    createFolderMutation,
    renameFolderMutation,
    duplicateBudgetMutation,
    deleteBudgetMutation,
    createSwimlaneMutation,
    addSubCategoryMutation,
    updateSwimlaneMutation,
    deleteSwimlaneMutation,
    createSwimlaneDetailsMutation,
    createDuplicateCategory,
    createDuplicateCategoryLineItem,
    updateSwimlaneDetailsMutation,
    deleteSwimlaneDetailsMutation,
    shareBudgetMutation,
    removeBudgetAccessMutation,
    removeMultiBudgetsMutuation,
    removeFolderMutuation,
    budgetDownloadMutation,

    getBudgetsStatus,
    budgetList,
    isFetchingBudgets,
    isErrorBudgets,
    refetchBudgets,
    getTabsStatus,
    openTabsListResponse,
    isFetchingTabs,
    isErrorTabs,
    refetchTabs,
    getFolderType,
    foldersData,
    isFetchingFolders,
    isErrorFoldersTypes,
    refetchFolders,
    getBudgetTypeStatus,
    budgetTypes,
    isFetchingBudgetTypes,
    isErrorBudgetTypes,
    refetchBudgetTypes,

    getBudgetIdStatus,
    budgetData,
    isFetchingBudgetId,
    isErrorBudgetId,
    refetchBudgetId,

    selectedBudget,
    setSelectedBudget,

    getContextualSuggestionsStatus,
    contextualSuggestions,
    isFetchingContextualSuggestions,
    isErrorContextualSuggestions,
    refetchContextualSuggestions,

    getCollaboratorsStatus,
    collaboratorsList,
    isFetchingCollaborators,
    isErrorCollaborators,
    refetchCollaborators,

    getBudgetPermissionsStatus,
    budgetPermissions,
    isFetchingBudgetPermissions,
    isErrorBudgetPermissions,
    refetchBudgetPermissions,

    showDetailedView,
    setShowDetailedView,
    activeSwimlane,
    setActiveSwimlane,
    swimlanes,
    setSwimlanes,
    projectTotal,
    setProjectTotal,
    budgetListData,
    setOpenTabsList,
    openTabsList,
    openTabsListInitial,
    folderListing,
    getbudgetDropdownData,
    showCreateBudget,
    showCreateFolder,
    showDeleteBudget,
    showDeleteMultiBudgets,
    showDeleteFolder,
    renameBudget,
    duplicateBudget,
    duplicateBudgetWithValues,
    hideCreateBudgetModal,
    showCreateBudgetModal,
    hideCreateFolderModal,
    showCreateFolderModal,
    hideDeleteBudgetModal,
    showDeleteBudgetModal,
    hideDeleteMultiBudgetModal,
    showDeleteMultiBudgetModal,
    showDeleteFolderModal,
    hideDeleteFolderModal,
    setBudgetToRename,
    clearBudgetRename,
    showShareBudgetModal,
    hideShareBudgetModal,
    showShareBudget,
    showBudgetSettings,
    showBudgetSettingsModal,
    hideBudgetSettingsModal,
    showLogOutModal,
    showNewBudgetId,
    printShow,
    setPrintShow,
    keyStore,
    setKeyStore,
    tabActive,
    setTabActive,
    setNewBudgetIdFunc,
    hideLogOutModal,
    logOutModalShow,
    addedContextualSuggestions,
    setAddedContextualSuggestions,
    menuActions,
    menuPermissions,
    changeTab,
    selectTab,
    renameTab,
    selectTabFromDropdown,
    updateSwimlaneCosts,
    isBudgetOwner,
    hasEditAccess,
    hasBudgetEditAccessInList,
    getBudgetType,
    updateSwimlaneItem,
  } = useContext(homeScreenContext);

  return {
    delData,
    folderData,
    setWindowReload,
    setSelectedDeldata,
    setFolderSelectedDeldata,
    createTabsMutation,
    makeAciveTabMutation,
    closeTabsMutation,
    createBudgetMutation,
    moveBudgetMutation,
    moveBudgetFolderMutation,
    createFolderMutation,
    renameFolderMutation,
    duplicateBudgetMutation,
    deleteBudgetMutation,
    createSwimlaneMutation,
    addSubCategoryMutation,
    updateSwimlaneMutation,
    deleteSwimlaneMutation,
    createSwimlaneDetailsMutation,
    createDuplicateCategory,
    createDuplicateCategoryLineItem,
    updateSwimlaneDetailsMutation,
    deleteSwimlaneDetailsMutation,
    shareBudgetMutation,
    removeBudgetAccessMutation,
    removeMultiBudgetsMutuation,
    removeFolderMutuation,
    budgetDownloadMutation,

    getBudgetsStatus,
    budgetList,
    isFetchingBudgets,
    isErrorBudgets,
    refetchBudgets,
    getTabsStatus,
    openTabsListResponse,
    isFetchingTabs,
    isErrorTabs,
    refetchTabs,
    getFolderType,
    foldersData,
    isFetchingFolders,
    isErrorFoldersTypes,
    refetchFolders,
    getBudgetTypeStatus,
    budgetTypes,
    isFetchingBudgetTypes,
    isErrorBudgetTypes,
    refetchBudgetTypes,

    getBudgetIdStatus,
    budgetData,
    isFetchingBudgetId,
    isErrorBudgetId,
    refetchBudgetId,

    selectedBudget,
    setSelectedBudget,

    getContextualSuggestionsStatus,
    contextualSuggestions,
    isFetchingContextualSuggestions,
    isErrorContextualSuggestions,
    refetchContextualSuggestions,

    getCollaboratorsStatus,
    collaboratorsList,
    isFetchingCollaborators,
    isErrorCollaborators,
    refetchCollaborators,

    getBudgetPermissionsStatus,
    budgetPermissions,
    isFetchingBudgetPermissions,
    isErrorBudgetPermissions,
    refetchBudgetPermissions,

    showDetailedView,
    setShowDetailedView,
    activeSwimlane,
    setActiveSwimlane,
    swimlanes,
    setSwimlanes,
    projectTotal,
    setProjectTotal,
    setOpenTabsList,
    budgetListData,
    openTabsList,
    openTabsListInitial,
    folderListing,
    getbudgetDropdownData,
    showCreateBudget,
    showCreateFolder,
    showDeleteBudget,
    showDeleteMultiBudgets,
    showDeleteFolder,
    renameBudget,
    duplicateBudget,
    duplicateBudgetWithValues,
    hideCreateBudgetModal,
    showCreateBudgetModal,
    hideCreateFolderModal,
    showCreateFolderModal,
    hideDeleteBudgetModal,
    showDeleteBudgetModal,
    hideDeleteMultiBudgetModal,
    showDeleteMultiBudgetModal,
    showDeleteFolderModal,
    hideDeleteFolderModal,
    setBudgetToRename,
    clearBudgetRename,
    showShareBudgetModal,
    hideShareBudgetModal,
    showShareBudget,
    showBudgetSettings,
    showBudgetSettingsModal,
    hideBudgetSettingsModal,
    showLogOutModal,
    showNewBudgetId,
    printShow,
    setPrintShow,
    keyStore,
    setKeyStore,
    tabActive,
    setTabActive,
    setNewBudgetIdFunc,
    hideLogOutModal,
    logOutModalShow,
    addedContextualSuggestions,
    setAddedContextualSuggestions,
    menuActions,
    menuPermissions,
    changeTab,
    selectTab,
    renameTab,
    selectTabFromDropdown,
    updateSwimlaneCosts,
    isBudgetOwner,
    hasEditAccess,
    hasBudgetEditAccessInList,
    getBudgetType,
    updateSwimlaneItem,
  };
};
