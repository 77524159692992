import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, Link, matchPath } from 'react-router-dom';
import { Navbar, Offcanvas, Nav, NavDropdown } from 'react-bootstrap';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/notifications.svg';
import Avatar from 'components/Avatar';

import SearchInput from 'components/SearchInput';
import { menuItems, profileMenu } from 'utils/constants';

import './styles.scss';
import ALL_ROUTES from 'config/routes';
import DropDownMenu from 'components/DropDownMenu';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { CircularProgress, Grid } from '@mui/material';
import GridLoader from 'components/GridLoader';

const Header = ({
  userData,
  menuActions,
  menuPermissions,
  logOutModalShow,
  budgetList,
  selectTab,
  handleLoader,
}) => {
  const { makeAciveTabMutation, createTabsMutation, refetchTabs } =
    useHomeScreenContext();
  const navigate = useNavigate();
  const searchBarRef = useRef();
  const { pathname } = useLocation();
  const [filteredBudgets, setFilteredBudgets] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setFilteredBudgets([]);
        setHighlightedIndex(-1);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const goToProfileView = () => {
    navigate(ALL_ROUTES.ACCOUNT_SETTINGS_CHILDREN.PROFILE);
  };

  const goToHomeScreen = () => {
    // navigate(ALL_ROUTES.HOME_SCREEN);
    window.location.href = `${ALL_ROUTES.HOME_SCREEN}`;
  };

  const isPathMatched = path => {
    return matchPath({ path }, pathname);
  };

  const filterMenuItems = () => {
    return menuItems?.filter(
      item => item?.show || item?.show_for?.some(path => isPathMatched(path))
    );
  };

  const filterSubMenuItems = subMenu => {
    return subMenu?.filter(
      item => item?.show && (menuPermissions?.[item.key] ?? true)
    );
  };

  const handleOnSelect = action => {
    if (menuActions?.[action]) {
      menuActions?.[action]();
    }
  };

  const handleProfileDropdownChange = action => {
    if (action === 'profile') {
      goToProfileView();
    } else if (action === 'logout') {
      logOutModalShow();
    }
  };

  const handleSearchChange = event => {
    const value = event?.target?.value?.trim();
    console.log('value@', value);
    const filteredList =
      value?.length &&
      budgetList?.filter(budget =>
        budget.name?.toLowerCase().includes(value?.toLowerCase())
      );

    setFilteredBudgets(filteredList || []);
    setHighlightedIndex(filteredList && filteredList.length > 0 ? 0 : -1); // Update highlightedIndex
  };

  const handleKeyDown = event => {
    if (filteredBudgets.length > 0) {
      if (event.key === 'ArrowDown') {
        setHighlightedIndex(prevIndex =>
          prevIndex === filteredBudgets.length - 1 ? 0 : prevIndex + 1
        );
      } else if (event.key === 'ArrowUp') {
        setHighlightedIndex(prevIndex =>
          prevIndex === 0 ? filteredBudgets.length - 1 : prevIndex - 1
        );
      } else if (event.key === 'Enter' && highlightedIndex >= 0) {
        // setLoading(true);
        // handleLoader(true);
        setLoading(true);
        handleResultClick(filteredBudgets[highlightedIndex])();
        setFilteredBudgets([]);
        setHighlightedIndex(-1);
        searchBarRef.current.querySelector('input').value = '';
      }
    }
  };

  const handleResultClick = budget => async () => {
    console.log('budgets', budget);
    setLoading(true);
    setHighlightedIndex(-1);
    setFilteredBudgets([]);
    searchBarRef.current.querySelector('input').value = '';
    const payload = [];
    payload?.push({
      budget: budget?.id,
    });
    try {
      const response = await createTabsMutation.mutateAsync(payload);
      await makeAciveTabMutation.mutateAsync(response?.data?.[0]?.id);
      const newTabs = response?.data;
      await refetchTabs();
      // handleLoader(false);
      setLoading(false);
    } catch (error) {
      console.log('Error in tabs creation', error);
      setLoading(false);
      // handleLoader(false);
    }
    setFilteredBudgets([]);
    setHighlightedIndex(-1);
  };

  return (
    <header className="app-header">
      <Navbar
        expand="md"
        className="d-flex px-5 align-items-center"
        onSelect={handleOnSelect}
      >
        <Navbar.Brand
          as="div"
          className="app-header__logo"
          onClick={goToHomeScreen}
        >
          <Logo />
        </Navbar.Brand>
        <Navbar.Offcanvas placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="d-flex align-items-center">
              <div className="app-header__logo" onClick={goToHomeScreen}>
                <Logo />
              </div>
              <div className="app-header__notification">
                <NotificationIcon />
              </div>
              <Avatar className="app-header__profile" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="d-flex align-items-center">
            <Nav className="app-header__menu align-items-end flex-grow-1 pe-3">
              {filterMenuItems()?.map(item => {
                if (item?.sub_menu_items?.length) {
                  return (
                    <NavDropdown
                      key={item.key}
                      title={item?.label}
                      className="app-header__menu__item app-header__menu__dropdown"
                    >
                      {filterSubMenuItems(item.sub_menu_items)?.map(
                        (subMenu, index) => {
                          return subMenu.type === 'divider' ? (
                            <NavDropdown.Divider
                              key={`divider-${item.key}-${index}`}
                            />
                          ) : (
                            <NavDropdown.Item
                              key={`${item.key}-${subMenu.key}-${index}`}
                              className="app-header__menu__item__submenu-item"
                              eventKey={subMenu?.key}
                              active={false}
                              as={'div'}
                            >
                              {subMenu.type === 'link' ? (
                                <Link
                                  to={subMenu.path}
                                  className="app-header__menu__item__submenu-item__link"
                                  target={subMenu.target}
                                >
                                  {subMenu.label}
                                </Link>
                              ) : (
                                subMenu.label
                              )}
                            </NavDropdown.Item>
                          );
                        }
                      )}
                    </NavDropdown>
                  );
                } else {
                  return (
                    <Nav.Link
                      key={item.key}
                      as="div"
                      onClick={() =>
                        (window.location.href = `/home/${item?.path}`)
                      }
                      className={
                        window?.location?.href?.split('/')[
                          window?.location?.href?.split('/').length - 1
                        ] !== 'budget-listing'
                          ? 'app-header__menu__item'
                          : 'app-header__menu__item active-header'
                      }
                      eventKey={item?.key}
                      active={false}
                    >
                      {item.label}
                    </Nav.Link>
                  );
                }
              })}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <div className="app-header__search" ref={searchBarRef}>
          {window?.location?.href?.split('/')[
            window?.location?.href?.split('/').length - 1
          ] !== 'budget-listing' && (
            <div className="d-flex gap-3">
              {loading && (
                <>
                  <CircularProgress size={30} thickness={2} color={'info'} />
                </>
              )}
              <SearchInput
                onChange={handleSearchChange}
                onFocus={handleSearchChange}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
          {filteredBudgets?.length > 0 && (
            <div className="app-header__search__result">
              {filteredBudgets?.map((budget, index) => (
                <div
                  key={`result-${budget.id}`}
                  className={`result-item ${
                    highlightedIndex === index ? 'highlighted' : ''
                  }`}
                  onClick={handleResultClick(budget)}
                >
                  {budget?.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="app-header__notification">
          <NotificationIcon />
        </div>
        {/* {loading && (
          <>
            <div className="loader-backdrop"></div>
            <div className="loader-wrapper">
              <div className="loader-square">
                <GridLoader />
              </div>
            </div>
          </>
        )} */}
        <DropDownMenu
          className="app-header__profile-dropdown"
          menuOptions={profileMenu}
          onChange={handleProfileDropdownChange}
          align={{ sm: 'end' }}
          toggleIcon={<></>}
          toggleLabel={
            <Avatar
              className="app-header__profile"
              name={userData?.name || userData?.email}
              image={
                userData?.profile_image ? (
                  <img src={userData?.profile_image} alt="profile-pic" />
                ) : null
              }
            />
          }
        />
        <Navbar.Toggle aria-controls="Navbar-expand" />
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  userData: PropTypes.object,
  menuActions: PropTypes.object.isRequired,
  menuPermissions: PropTypes.object.isRequired,
  logOutModalShow: PropTypes.func.isRequired,
  budgetList: PropTypes.array,
  selectTab: PropTypes.func.isRequired,
  handleLoader: PropTypes.func.isRequired,
};

Header.defaultProps = {
  userData: {},
  budgetList: [],
};

export default Header;
